/* ========================================
Imports
======================================== */

@import "./scss/media";
@import "./scss/normalize";

/* ========================================
Vars
======================================== */

//Fonts
$display: "Playfair Display", serif;
$body: "Varta", sans-serif;

//Colors
$grey: #808086;
$grey-2: #e3e4e8;
$white: #fff;
$black: #000;

/* ========================================
Defaults
======================================== */

body {
  font-family: $body;
  font-size: 18px;
  line-height: 2;

  @media #{$mobile-only} {
    font-size: 16px;
  }

  * {
    box-sizing: border-box;
  }
}

a {
  font-weight: 700;
  color: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $display;
  line-height: 1;
  //text-transform: uppercase;
}

img {
  max-width: 100%;
}

.content {
  max-width: 930px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}

/* ========================================
Loading
======================================== */

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
  transition: 0.6s;
  pointer-events: none;
  z-index: 100;

  body.loaded & {
    opacity: 0;
  }
}

.App {
  transition: 0.3s;
  opacity: 0;
  height: 100vh;
  width: 100%;
  overflow: hidden;

  body.loaded & {
    opacity: 1;
    height: auto;
    overflow: visible;
  }
}

/* ========================================
Hero
======================================== */

.headerSpacer {
  height: 10vh;
}

.heroContent {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  transform: scale(1.3);
  transition: 1.2s;

  @media #{$mobile-only} {
    flex-direction: column;
  }

  &.visible {
    transform: none;
  }
}

.heroContent__text {
  width: 50%;
  padding-right: 40px;

  @media #{$mobile-only} {
    width: 100%;
    padding-right: 0px;
  }
}

.dearMark {
  font-size: 93px;
  margin: 0;
  line-height: 1;
  letter-spacing: 1.9px;

  @media #{$mobile-only} {
    font-size: 60px;
  }

  span {
    transform: translate(-10px, 20px) skew(-10deg);
    opacity: 0;
    transition: 0.9s;
    display: inline-block;

    @for $i from 1 through 20 {
      $delay: $i * 0.07;
      &:nth-child(#{$i}) {
        transition-delay: #{$delay + "s"};
      }
    }
  }

  &.visible {
    span {
      opacity: 1;
      transform: none;
    }
  }
}

.dearMark__subtitle {
  font-size: 14px;
  width: 80%;
  color: $grey;
}

.heroContent__image {
  width: 50%;
  margin-top: 140px;
  position: relative;

  @media #{$mobile-only} {
    width: 100%;
    margin-top: 0;
  }

  img {
    transform: scale(1.1);
    transition: 2.2s;
  }

  &.visible {
    img {
      transform: none;
    }
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.circleAnimation__animationContainer {
  opacity: 0;
  transition: 0.8s;
  transition-delay: 0.4s;

  .heroContent__image.visible & {
    opacity: 1;
  }
}

.circleAnimation {
  width: 150px;
  height: 150px;
  background-image: url("./images/circle_design.svg?wfw=wef");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  animation: 10s rotate linear infinite;
  position: absolute;
  top: -50px;
  right: -50px;
  z-index: 4;

  @media #{$mobile-only} {
    top: 20px;
    right: 20px;
    width: 100px;
    height: 100px;
  }
}

.heroContent__image__inner {
  position: relative;
  overflow: hidden;
  z-index: 0;
}

.heroContent__image__overlay {
  position: absolute;
  width: 102%;
  height: 102%;
  background-color: $white;
  transition: 1.8s;
  z-index: 2;

  .heroContent__image.visible & {
    transform: translateX(101%);
  }
}

.heroContent__imageCaption {
  text-align: center;
  color: $grey;
  font-size: 12px;
  margin: 5px auto 0;
}

/* ========================================
Accordion section
======================================== */

@mixin openAccorion {
  .accordion.open & {
    @content;
  }
}

.accordionSection {
  padding-top: 60px;
}

.accordian__title {
  font-size: 26px;
  margin: 0;
  padding: 30px 0 30px 55px;
  cursor: pointer;
  position: relative;

  @media #{$mobile-only} {
    font-size: 20px;
    padding-left: 30px;
    padding: 20px 0 20px 30px;
  }
}

.accordion__plus,
.accordion__minus {
  display: block;
  position: absolute;
  left: 0;
  top: 45px;
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  background-image: url("./images/plus.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;

  @media #{$mobile-only} {
    top: 31px;
  }
}

.accordion__minus {
  background-image: url("./images/minus.svg");
}

.accordian {
  border-top: 1px solid $grey-2;
  border-bottom: 1px solid $grey-2;
  margin-top: -1px;
  position: relative;
  opacity: 0.5;
  transition: 0.2s;

  &:hover {
    opacity: 1;
  }

  &.open {
    opacity: 1;
    padding-bottom: 30px;
  }
}

.accordian__content {
  position: relative;
  overflow: hidden;
  transition: 0.4s;

  & > *:first-child {
    margin-top: 0;
  }

  & > *:last-child {
    margin-bottom: 0;
  }
}

.portfolioText {
}

.portfolioItem {
  position: relative;
  overflow: hidden;
  border-radius: 15px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-start;
  text-decoration: none;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
  transition: 0.3s;

  @media #{$mobile-only} {
    border-radius: 8px;
  }

  &:hover {
    @media #{$small} {
      transform: translateY(-10px);
    }
  }

  &:not(:last-child) {
    margin-bottom: 25px;
  }
}

.portfolioItem__image {
  width: 200px;
  height: 200px;

  @media #{$mobile-only} {
    width: 100px;
    height: 100px;
  }
}

.portfolioItem__text {
  padding: 0 20px;
}

.portfolioItem__title {
  font-size: 24px;
  margin: 0;

  @media #{$mobile-only} {
    font-size: 18px;
    margin-bottom: 10px;
  }
}

.portfolioItem__subtitle,
.portfolioItem__platform {
  margin: 10px 0;
  font-size: 12px;
  color: $grey;
  line-height: 1.2;

  @media #{$mobile-only} {
    margin: 5px 0;
  }
}

.references {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: row wrap;
  padding-top: 40px;
}

.referenceItem {
  width: 48%;
  text-align: center;

  @media #{$mobile-only} {
    width: 100%;
  }

  &:not(:last-child) {
    margin-bottom: 30px;
  }

  * {
    line-height: 1.4;
    text-decoration: none;
  }
}

.referenceItem__image {
  img {
    width: 100px;
    height: 100px;
    border-radius: 100px;
    margin-bottom: -75px;
  }
}

.referenceItem__text {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
  background-color: $white;
  border-radius: 10px;
  padding: 75px 15px 30px 15px;
}

.referenceItem__name {
  margin: 0 0 5px 0;
}

.referenceItem__title,
.referenceItem__email,
.referenceItem__phoneNumber,
.referenceItem__linkedIn {
  margin: 0;
  font-size: 14px;
}

.referenceItem__email {
  margin: 0;
  color: $grey;
}

.referenceItem__phoneNumber {
  margin: 0;
  color: $grey;
}

.referenceItem__linkedIn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;

  img {
    margin-right: 5px;
  }
}

/* ========================================
Closing remarks
======================================== */

.closingRemarks {
  width: 100%;
  margin-top: 60px;
  padding: 80px 0;
  color: $white;
  background-color: $black;
  width: 100%;
}

.closingRemarks__sig {
  max-width: 200px;
  margin-left: -30px;
}

.closingRemarks__name {
}

.closingRemarks__emailLinkedIn {
  display: flex;
  align-items: center;

  @media #{$mobile-only} {
    flex-direction: column;
    align-items: flex-start;
  }
}

.closingRemarks__email {
  &:after {
    @media #{$small} {
      content: "";
      display: inline-block;
      vertical-align: middle;
      height: 30px;
      width: 1px;
      background-color: $grey-2;
      margin: 0 30px;
    }
  }
}

.closingRemarks__linkedInContainer {
  a {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      margin-right: 10px;
    }
  }
}

/* ========================================
Scroll 
======================================== */

.fadeUpScroll {
  transform: translateY(120px) skew(-5deg);
  transition: 1.2s;
  opacity: 0;

  &.visible {
    opacity: 1;
    transform: none;
  }
}
